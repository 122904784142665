<template>
  <div>
    <b-img
      src="../../assets/images/logo/betded-logo.png"
      fluid
      alt="RIZZ888"
    ></b-img>
  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue'

export default {
  components: {
    BImg
  }
}
</script>
